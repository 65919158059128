import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../../environments/environment";
import { delay, firstValueFrom, map, Observable, of, tap } from "rxjs";
import { SessionService } from "../session/session.service";
import { HelperService } from "./helper.service";
import { DesignToolPosition, PositionDesign } from "@core/models/designTool";
import { Cart } from "@core/models/catalog";

@Injectable({ providedIn: 'root' })
export class MongoDbService {
  root: string;
  endpoint: string;
  cacheBrands: any;
  cacheCategories: any[];

  constructor(
    protected http: HttpClient,
    protected session: SessionService,
    protected helper: HelperService
  ) { }

  determineEndpoint = (search) => {
    if (search.length === 1 && search[0].hasOwnProperty('search')) return `${this.root}products/search`;
    if (search.length === 1 && search[0].hasOwnProperty('decoration')) return `${this.root}products/decoration`;
    return `${this.root}products/styles`;
  };

  searchProducts(search, currentPage, sort): Observable<any> {
    this.root = environment.apiMicroservice
    this.endpoint = this.determineEndpoint(search);
    const page = currentPage;
    const pageSize = 100;

    const searchObj = search.reduce((acc, obj) => {
      for (const [key, value] of Object.entries(obj)) {
        if (acc[key]) {
          acc[key].push(value);
        } else {
          acc[key] = [value];
        }
      }
      return acc;
    }, {});

    const queryParams = Object.entries(searchObj)
      .map(([key, values]) => `${key}=${(values as string[]).join(',')}`)
      .join('&');

    const url = `${this.endpoint}?${queryParams}&page=${page}&pageSize=${pageSize}&sort=${sort}`;
    // const url = `${this.endpoint}?${queryParams}`;

    return new Observable((observer) => {
      this.http.get(url).subscribe({
        next: (response) => {
          observer.next(response);
          observer.complete();
        },
        error: (error) => {
          console.error('Error searching products:', error);
          observer.error(error);
        },
      });
    });
  }

  getBrands() {
    const url = `${environment.apiMicroservice}products/brands`

    if (this.cacheBrands) {
      return of(this.cacheBrands)
    } else {
      return this.http.get(url).pipe(
        tap(data => this.cacheBrands = data),
        map((data: any) => {


          // Sort the array alphabetically
          data = data.sort((a: string, b: string) => a.localeCompare(b));
          return data
        }),
        delay(300),
      );
    }
  }

  getCategories() {
    const url = `${environment.apiMicroservice}products/categories`

    return this.http.get(url).pipe(
      map((data: any) => {
        return data
      }),
      delay(300),
    );
  }

  getCarts(clientId) {
    const url = `${environment.apiMicroservice}marketplace/carts/${clientId}`

    return this.http.get(url).pipe(
      map((data: any) => {
        return data
      }),
      delay(300),
    );
  }

  cartTemplate(): Cart {
    const cart = {
      cartName: '',
      cartId: this.helper.generateUUID(),
      clientId: this.session.getActiveClient().id,
      version: Math.floor(new Date().getTime() / 1000),
      paid: false,
      active: true,
      designs: [],
      notes: '',
      contact: null,
      shipping: [],
      payment: null,
      subTotal: 0,
      tax: 0,
      grandTotal: 0,
    } as Cart

    return cart
  }

  addCart(cart: Cart): Observable<any> {
    const url = `${environment.apiMicroservice}marketplace/carts/add`;
    return this.http.post(url, cart);
  }

  addDesign(design: any): Observable<any> {
    const designDTO = {...design, clientId: this.session.getActiveClient().id, createdBy: this.session.getUser()}
    const url = `${environment.apiMicroservice}marketplace/carts/design/add`;
    return this.http.post(url, designDTO);
  }

  async placeOrder(cart: Cart, recaptchaToken: string) {
    // 1. Create Order in Legacy **ENDPOINT APIv2 ----- still need to test thoroughly
    const orderResult = await this.createOrderLegacy(cart, recaptchaToken)
    const orderNumber = orderResult['order']['display_number']

    // 2. Charge Credit Card & Authorize payment with CardConnect **ENDPOINT MICROSERVICE
    await this.authorizePaymentCardConnect(cart.payment, orderNumber)

    // 3. Send a confirmation email of purchase
    await this.sendConfirmationEmail(cart, orderNumber);

    return orderNumber;
  }

  async createOrderLegacy(cart: Cart, recaptchaToken: string) {
    // pass in cart payload to APIv2 endpoint to create order in Legacy
    const url = `${environment.apiUrl2}api/v2/order/create?recaptchaToken=${recaptchaToken}`
    return firstValueFrom(this.http.post(url, cart));
  }

  async authorizePaymentCardConnect(payment, orderNumber) {
    const cardConnectDTO = {
      account: payment.account,
      amount: payment.amount,
      name: payment.name,
      tokenize: "Y",
      cvv2: payment.cvv2,
      expiry: payment.expiry,
      orderid: orderNumber.toString(),
      capture: "Y",
      address: payment.billingAddress.address,
      city: payment.billingAddress.city,
      region: payment.billingAddress.state,
      postal: payment.billingAddress.zip,
      profile: "Y"
    }
    // pass in cart payment payload to Microservice endpoint to authorize payment for Order
    const url = `${environment.apiMicroservice}marketplace/payment/authorize`;
    const response = await firstValueFrom(this.http.post(url, cardConnectDTO));
    return response;
  }

  async sendConfirmationEmail(cart, orderNum) {
    const customer = {
      orderNumber: orderNum,
      cartName: cart.cartName,
      contact: cart.contact,
      designs: cart.designs
    }
    const url = `${environment.apiUrl2}api/v2/pdf/confirmation`
    return firstValueFrom(this.http.post(url, customer));

  }

 async getCustomerCodeForTax(clientId) {
  const url = `${environment.apiMicroservice}marketplace/carts/getCustomer/${clientId}`
  const response = await firstValueFrom(this.http.get(url));

  return response;
 }

 async getTax(customerCode, shipTo, lineItems, description) {
    const taxDTO = {
      "customerCode": customerCode,
      "addresses": {
          "shipFrom": {
              "line1": "1151 w 40th Street",
              "city": "Chicago",
              "region": "IL",
              "country": "US",
              "postalCode": "60609"
          },
          "shipTo": shipTo
      },
      "lines": lineItems,
      "description": description
  }
    const url = `${environment.apiMicroservice}marketplace/carts/getTax`
    return await firstValueFrom(this.http.post(url, taxDTO));
  }

  async commitTax(customerCode, shipTo, lineItems, description) {
    const taxDTO = {
      "customerCode": customerCode,
      "addresses": {
          "shipFrom": {
              "line1": "1151 w 40th Street",
              "city": "Chicago",
              "region": "IL",
              "country": "US",
              "postalCode": "60609"
          },
          "shipTo": shipTo
      },
      "lines": lineItems,
      "description": description
  }
    const url = `${environment.apiMicroservice}marketplace/carts/commitTax`
    const response = await firstValueFrom(this.http.post(url, taxDTO));
    return response;
  }

  updateDesignPositionCanvas(designId: string, position: string, canvas: PositionDesign[], decorationType: string): Observable<any> {
    const url = `${environment.apiMicroservice}marketplace/carts/design/` + designId;
    return this.http.patch(url, {position, canvas, decorationType});
  }

  updateDesignPositions(designId: string, positions: Partial<DesignToolPosition>[]): Observable<any> {
    const url = `${environment.apiMicroservice}marketplace/carts/design/positions/` + designId;
    return this.http.patch(url, {positions});
  }

  async validatePromoCode(code: string) {

    const url = `${environment.apiMicroservice}marketplace/carts/promo/validate/${encodeURIComponent(code)}`
    const response = await firstValueFrom(this.http.get(url));
  
    return response;
  }

  async addDiscountTransaction(promoCode: string, totalDiscount: number, orderNumber: number, cartId: string) {
    const discountDTO = {
      cartId: cartId,
      promoCode: promoCode,
      discount: parseFloat(totalDiscount.toFixed(2)),
      clientId: this.session.getActiveClient().id,
      orderId: orderNumber,
    }
    const url = `${environment.apiMicroservice}marketplace/discount/save`;
    const response = await firstValueFrom(this.http.post(url, discountDTO));
    return response;
  }
}
